let catalogData = [
    // {
    //     name: "Master",
    //     year: 2020,
    //     link: "https://e.issuu.com/anonymous-embed.html?u=fisherathleticequipment&d=2020mastercatalogforweb",
    //     image: "https://lh3.googleusercontent.com/BRQPOyzeEkKCEKl71oTpzdNoR-gCenr8w95ntOralnn9ZtGvsMEsY4Sh9MynsPLmm3h272gg7kBQO-rcik94ZrK_wrTgWu3IS-D3wlA8LgCdN0XpRrpAlwfA5c5djNzmx4aI8BBNFA=w233-h300-no"
    // },
    {
        name: "Football",
        year: 2024,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_football_catalog-web?fr=sNjQ3MDY4NjM2OTg",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2024-football-cover.jpg?alt=media&token=c7fcaca7-c5b8-4713-a836-dff2c113115e"
    },
    {
        name: "Baseball",
        year: 2024,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_baseball_dealer_5266f930c3c150?fr=sMjk4ZjY4NjM2OTg",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2024-baseball-cover-rev2.jpg?alt=media&token=2da5caa8-7097-4550-b466-87b278656c33"
    },
    {
        name: "Facility Branding",
        year: 2024,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_facility_branding?fr=xKAE9_zU1NQ",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/catalogs%2Ffacility-branding-catalog-thumb-2024.jpg?alt=media&token=03bcd122-56f0-4ac7-839b-0d4916f77815"
    },
    {
        name: "Chairs & Stools",
        year: 2024,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_chairs_stools-update-2?fr=sMWFiZTY4NjM2OTg",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2024-chairs-cover.jpg?alt=media&token=715d89b2-d8a0-4eb8-aad6-fd4fe64fb68c"
    },
    {
        name: "Protective Padding",
        year: 2024,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_protectivepadding_267fa6c27d8d31?fr=sNTJhNTY4NjM2OTg",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2024-protective-padding-cover-rev2.jpg?alt=media&token=36399af2-eefa-4c02-828d-0c00d74873d5"
    },
    {
        name: "Track & Field",
        year: 2024,
        link: "https://issuu.com/fisherathleticequipment/docs/2024-track_field_dealer?fr=xKAE9_zU1NQ",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/catalogs%2Ftrack-field-catalog-thumb-2024.jpg?alt=media&token=9d1c4adb-1cae-4153-a82e-b242c5ac2f34"
    }
    // {
    //     name: "PPE",
    //     year: 2020,
    //     link: "https://issuu.com/fisherathleticequipment/docs/2020_covid19_package_institute-no_fold-comp?fr=sYTMyNDM3MDQ0Mg",
    //     image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2020-ppe-catalog-web-sm.jpg?alt=media&token=73e3694b-9dd8-4e3e-8f7e-4e42fdf73f72"
    // }
]

export default catalogData