import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useMediaQuery } from "@material-ui/core"
import ImageHero from './subComponents/ImageHero'
import ProductTrain from './ProductTrain'
import productData from '../assets/data/productData'
import Categories from "./pages/Categories"

import './Home.css'

const Home = () => {

    useEffect(() => {
        document.title = `Fisher Athletic Equipment`
      })

    useEffect(() => {
        window.scrollTo(0 ,0)
    },[])

    // variable for using a media query
    const isSmall = useMediaQuery('(max-width: 600px)');
    const isMedium = useMediaQuery('(max-width: 1000px)');

    return (
        <div className='home-div'>
            <Link to='/HH4R/item'>
            <div className='page-intro-container'>
                <div className='page-intro-img-container'>
                    <img className='page-intro-img skeleton' src='https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhedgehog-landing-page-13.jpg?alt=media&token=d0ddefda-bf07-42af-bc72-67dc37f0ed7f' alt='Hedgehog Tackle'  onLoad={() => console.log('loaded')} />
                    {/* <video
                        className='page-intro-img skeleton'
                        poster='https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-sky-poster.jpg?alt=media&token=f63849a7-a5c9-452f-9077-d4134a7fa3ef'
                        width='1920'
                        height='1080'
                        autoPlay
                        loop
                        muted
                        disablePictureInPicture
                        playsInline
                    >
                        <source src='https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-sky.mp4?alt=media&token=6da38f4c-8c68-4cf2-b12f-e77ba3e07196' type='video/mp4' />
                    </video> */}
                </div>
                <div className={isSmall ? 'page-intro-content-sm' : isMedium ? 'page-intro-content-md' : 'page-intro-content-lg'}>
                    {/* <h2 className={isSmall ? 'page-intro-txt-sm' : isMedium ? 'page-intro-txt-md' : 'page-intro-txt-lg'}>GET EQUIPPED</h2> */}
                    {/* <Link className={isSmall ? 'page-intro-btn-sm' : isMedium ? 'page-intro-btn-md' : 'page-intro-btn-lg'} to='/Football/subcategories'>VIEW FOOTBALL</Link> */}
                </div>
            </div>
            </Link>
            <div className='featured-products-home'>
                <ProductTrain 
                    header='FEATURED PRODUCTS' 
                    subHeader='Top products of the week' 
                    items={productData.filter(item => {
                        let isTrue = false
                        let skuArr = ['ch100dp', 'dpodc60', 'dmws', 'rpsw184', 'hJ101824', 'stl1000dp']
                        skuArr.forEach(sku => item.sku === sku ? isTrue = true : null)
                        return isTrue
                    })} 
                />
                </div>
            <div id={isSmall ? 'image_hero_group_sm' : isMedium ? 'image_hero_group_md' : 'image_hero_group_lg'}>
                <ImageHero
                image='https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-square-wall-padding-1.jpg?alt=media&token=c8c10d45-0023-489c-9242-0e96dfaa7567'
                header='Custom Wall Padding'
                subheader='Change the atmosphere in your facility.'
                middle={false}
                darkTxt={false}
                link='/wp2so2/item'
                imgAlt='Digitally Printed Wall Padding'
                />
                <ImageHero
                image='https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-square-wall-smooth-1.jpg?alt=media&token=586a8dc4-1a99-43b7-8d4f-ea5d23ba8734'
                header='Transform your Facility'
                subheader='With custom graphics for smooth or rough wall surfaces'
                middle={false}
                darkTxt={false}
                link='/facility%20branding/adhesive%20graphics/Wall%20Graphics/groups'
                imgAlt='Digitally Printed Wall Graphics'
                />
            </div>
            <div id='home-categories-container'>
                <Categories header='Search by category' />
            </div>
                <div id={isSmall ? 'image_hero_group_sm' : isMedium ? 'image_hero_group_md' : 'image_hero_group_lg'}>
                <ImageHero 
                image='https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-square-on-deck-circles-2.jpg?alt=media&token=f1eba7ac-e638-4c2c-ab5e-8a2aaf8647d0'
                header='Brand your field with'
                subheader='Diamond Pro On-Deck Circles!'
                middle={false}
                darkTxt={false}
                link='/dpodc60/item'
                imgAlt='Diamond Pro On-Deck Circles'
                />
                <ImageHero 
                image='https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-square-scorers-table-1.jpg?alt=media&token=6ca833d5-3858-4db6-9bf5-4bd13613c512'
                header='Brand your gym with'
                subheader='Scorers Tables!'
                middle={false}
                darkTxt={false}
                link='/st25b/item'
                imgAlt='Scorers Table'
                />
            </div>
        </div>
    )
}

export default Home